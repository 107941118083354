import { InjectionToken } from '@angular/core';
const ENVIRONMENT = new InjectionToken('tas-environment');

/*
 * Public API Surface of tas-environment
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ENVIRONMENT };
